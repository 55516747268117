<template>
  <div class="Settlement-wrapper">
    <router-link to="./settlementApply" class="Settlement" >
      <img class="Settlement-img" src="../../assets/images/lipei1.png" />
      <div class="Settlement-text">我要理赔</div>
    </router-link>

    <router-link to="/settlementRecord" class="Settlement" >
      <img class="Settlement-img" src="../../assets/images/lipei1.png" />
      <div class="Settlement-text">理赔记录</div>
    </router-link>
  </div>
</template>

<script>
import './Settlement.less'
import { baseConfig } from '../../utils/request'
export default {
  name: 'Settlement',
  data() {
    return {

    }
  },
  mounted() {
	  let loginInfo = localStorage.getItem('loginInfo');
	  if (!this.$route.query.sourceType && (!loginInfo || !JSON.parse(loginInfo).openId || !JSON.parse(loginInfo).unitId)) {
	    const url = `${baseConfig.locationHref}`;
	    const oldPath = encodeURIComponent(window.location.href.split(location.origin)[1] + '&login');
	    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${baseConfig.appid}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=${oldPath}`
	  }
  },
  methods: {

  },
}
</script>

